const state = {
  curUserInfo: {},
  token: '',
  userInfo: {}
}
// getters
const getters = {
  curUserInfo: state => state.curUserInfo,
  token: state => state.token,
  userInfo: state => state.userInfo,
}
// mutations
const mutations = {
  SET_USER_INFO: (state, userInfo) => {
    state.curUserInfo = userInfo
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  delToken: (state) => {
    state.token = ''
    localStorage.removeItem('token')
  }
}
const actions = {
  setToken ({ commit }, token) {
      commit('SET_TOKEN', token)
  },
  // 用户名登录
  sign_in({ commit }, userInfo) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', userInfo.token)
      commit('SET_USER_INFO', userInfo)
      resolve()
    })
  },
  set_userInfo({ commit }, userInfo) {
    commit('SET_USERINFO', userInfo)
  },
  // 退出
  log_out ({ commit }) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', '')
      commit('SET_USER_INFO', null)
      resolve()
    })
  },
  //修改用户信息
  setUserInfo({ commit }, userInfo){
    // console.log(userInfo,'xnxi ')
    commit('SET_USER_INFO', userInfo)
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
