<template>
  <el-menu
      background-color="#ffffff"
      text-color="#595959"
      active-text-color="#0564eb"
      :default-active="$route.path"
      :router="true"
      :unique-opened="true"
    >
      <TopNavItem :routes="routes" />
    </el-menu>
</template>

<script>
export default {
  name: "BaseSidebar",
  props: {
    // boxWidth: {
    //   type: String,
    //   default: "100%",
    // },
    // boxHeight: {
    //   type: String,
    //   default: "400px",
    // },
    // title: {
    //   type: String,
    //   default: "",
    // },
    // isSwiper: {
    //   type: Boolean,
    //   default: false,
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

</style>
