<template>
  <div :class="[className1]">
    <!-- <scroll-bar class="scroll-bar"> -->
    <el-menu
      :mode="mode"
      background-color="#ffffff"
      text-color="#595959"
      active-text-color="#0564eb"
      :default-active="$route.path"
      :router="true"
      :unique-opened="true"
    >
      <TopNavItem :routes="routes" :className="className" />
    </el-menu>
    <!-- </scroll-bar> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import TopNavItem from "./TopNavItem.vue";
import ScrollBar from "@/components/ScrollBar";
import menuList0 from "@/static/json/menuList.json";

export default {
  name: "TopNav",
  components: { TopNavItem, ScrollBar },
  props: {
    name: {
      type: String,
      default: "TopNav",
    },
    className1: {
      type: String,
      default: "h-nav",
    },
    className2: {
      type: String,
      default: "menu-wrapper",
    },
    mode: {
      type: String,
      default: "vertical", // 默认垂直
    },
    menuList: {
      type: Array,
      default: () => menuList0,
    },
  },
  computed: {
    ...mapState(["menus", "userInfo"]),
    routes() {
      if (this.name === "TopNav") {
        return this.menuList;
      } else {
        const arr = ['home', 'HD4k', 'TVOS', 'broadband', 'AIO', 'serviceSupport']
        let index
        for (let i in arr) {
          // console.log(arr[i]);
          if ((this.$route.path.includes(arr[i])) === true) index = i
        }
        return this.menuList[index].children
      }

      return this.menus.menuList;
    },
    // // 默认展开左侧菜单栏目
    // isCollapse() {
    //   return this.menus.open;
    // },
    curUserInfo() {
      return this.userInfo.curUserInfo;
    },
    className() {
      return this.className2;
    },
  },

  // watch: {
  //   name: {
  //     handler(val, oldVal) {
  //       this.$options.name = val;
  //     },
  //     immediate: true,
  //   },
  // },
  created() {
    this.$options.name = this.name;
  },
  mounted() {
    // console.log(this.menuList);
    // console.log(this.routes);
  },
};
</script>
<style lang="scss">
/*.scroll-bar {*/
/*position: fixed !important;*/
/*top: 48px;*/
/*width: 180px !important;*/
/*}*/

</style>
