const state = {
    demandId: '',
    taskId: '',
    isOpen: false,
    noReadTotal: 0,
    pageCurrent: 1
}
// getters
const getters = {
    demandId: state => state.demandId,
    pageCurrent: state => state.pageCurrent,
    taskId: state => state.taskId,
    isOpen: state => state.isOpen,
    noReadTotal: state => state.noReadTotal,
}
// mutations
const mutations = {
    SET_DEMAND_ID: (state, demandId) => {
        state.demandId = demandId
    },
    SET_PAGE_CURRENT: (state, pageCurrent) => {
        state.pageCurrent = pageCurrent
    },
    SET_TASK_ID: (state, taskId) => {
        state.taskId = taskId
    },
    SET_NOREAD_TOTAL: (state, noReadTotal) => {
        state.noReadTotal = noReadTotal
    },
    SET_IS_OPEN: (state, isOpen) => {
        state.isOpen = isOpen
    }
}
const actions = {
    setDemandId ({ commit }, demandId) {
        commit('SET_DEMAND_ID', demandId)
    },
    setPageCurrent ({ commit }, pageCurrent) {
        commit('SET_PAGE_CURRENT', pageCurrent)
    },
    setTaskId ({ commit }, taskId) {
        commit('SET_TASK_ID', taskId)
    },
    setIsOpen ({ commit }, isOpen) {
        commit('SET_IS_OPEN', isOpen)
    },
    setNoReadTotal ({ commit }, noReadTotal) {
        commit('SET_NOREAD_TOTAL', noReadTotal)
    }
}
export default {
    state,
    getters,
    actions,
    mutations
}
