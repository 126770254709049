<template>
  <div ref="scrollContainer" class="scroll-container" @wheel.prevent="handleScroll">
    <div ref="scrollWrapper" class="scroll-wrapper">
      <slot/>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

const delta = 15
export default {
  name: 'ScrollBar',
  data () {
    return {
      min: 80
    }
  },
  computed: {
    ...mapState([
      'menus'
    ])
  },
  watch: {
    'sidebar.opened' (val) {
      if (!val) {
        this.top = 0
        this.min = 0
      } else {
        this.top = 80
        this.min = 80
      }
    }
  },
  created () {
    // if (!this.menus.open) {
    //   this.min = 0
    // }
    this.min = 0
  },
  methods: {
    handleScroll (e) {
      const eventDelta = e.wheelDelta || -e.deltaY * 3
      const $container = this.$refs.scrollContainer
      const $containerHeight = $container.offsetHeight
      const $wrapper = this.$refs.scrollWrapper
      const $wrapperHeight = $wrapper.offsetHeight
      if (eventDelta > 0) {
        this.top = Math.min(this.min, this.top + eventDelta)
      } else {
        if ($containerHeight - delta < $wrapperHeight) {
          if (this.top < -($wrapperHeight - $containerHeight + delta)) {
            this.top = this.top
          } else {
            this.top = Math.max(this.top + eventDelta, $containerHeight - $wrapperHeight - delta)
          }
        } else {
          this.top = this.min
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$menubg: #fff;

.scroll-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $menubg;

  .scroll-wrapper {
    position: absolute;
    width: 100% !important;
  }
}
</style>
