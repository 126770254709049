const state = {
  cityId: '1002'
}
// getters
const getters = {
  cityId: state => state.cityId,
}
// mutations
const mutations = {
   SET_CITYID:(state,cityId)=>{
    // alert(cityId)
    state.cityId = cityId
   }
}
const actions = {
  setCityId ({ commit }, cityId) {
    commit('SET_CITYID', cityId)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}