const state = {
  loading: false
}
// getters
const getters = {
  loading: state => state.loading,
}
// mutations
const mutations = {
   SET_LOADING:(state,loading)=>{
    state.loading =loading
   }
}
const actions = {
  setLoading ({ commit }, loading) {
    commit('SET_LOADING', loading)
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
