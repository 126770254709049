<template>
  <div class="box-area" :style="{ width: boxWidth }">
    <p v-if="title" class="title">{{ title }}</p>
    <div v-if="isSwiper" class="swiper"><slot name="swiper"></slot></div>
    <div v-else class="content"><slot name="content"></slot></div>
  </div>
</template>

<script>
export default {
  name: "BaseBox",
  props: {
    boxWidth: {
      type: String,
      default: "100%",
    },
    boxHeight: {
      type: String,
      default: "400px",
    },
    title: {
      type: String,
      default: "",
    },
    isSwiper: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.box-area {
  width: 1200px;
  margin: 30px auto 0;
  border: 1px solid $border-color;
  overflow: hidden;
  .title {
    border-bottom: 1px solid $border-color;
    padding-left: 30px;
    font-size: 24px;
    line-height: 46px;
    height: 46px;
  }

  .swiper {
    // width: 100%;
    // @include flex-center;
  }
  .content {
    height: calc(100% - 46px);
    @include flex-center;

    img {
      transition: all 0.3s ease-in;

      &:hover {
        transform: scale(1.01);
      }
    }
    .center {
      margin: 0 10px;
    }
  }
}
</style>
