export default {
  namespaced: true,
  state: {
    whiteList: [],
    templateList: []
  },
  getters: {},
  mutations: {
    SET_WHITE_LIST: (state, list) => {
      state.whiteList = list
    },
    SET_TEMPLATE_LIST (state, list) {
      state.templateList = list
    }
  },
  actions: {}
}