import '@babel/polyfill'
import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import {
  sync
} from 'vuex-router-sync'
import App from './App'
import {
  router
} from './router'
import store from './store'
import UtilsPlugin from './utils'
import 'element-ui/lib/theme-chalk/index.css'
import * as filters from './filters/index'

// import Xwui from '@xw/gitxwui'
// import BaiduMap from 'vue-baidu-map'

// Vue.use(BaiduMap, {

//   ak: 'sTgwxTFe9mEU9oFxCZHx8mmxg0gYFzgD'

// })
import TopNav from '@/layout/components/TopNav/Index.vue'
Vue.component('TopNav', TopNav)

Vue.prototype.$EventBus = new Vue()
const requireComponent = require.context(
  './components/base',
  false,
  /Base[A-Z]\w+\.(vue|js)$/
)

Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === 'custName') {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, newVal);
  }
}


requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)

  const componentName = upperFirst(
    camelCase(
      fileName
      .split('/')
      .pop()
      .replace(/\.\w+$/, '')
    )
  )

  Vue.component(
    componentName,
    componentConfig.default || componentConfig
  )
})

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.use(UtilsPlugin)
// Vue.use(Xwui);
import ElementUI from 'element-ui'
Vue.use(ElementUI)
sync(store, router)
window.$globalHub = new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')