<template>
  <div id="app" style="height:100%;">
    <!-- 展示应急页面 -->
    <div v-if="flag == 2">
      <img src="./assets/agent.jpg" alt="" width="100%">
    </div>
    <div v-else>
      <div class="loading" v-if="loading"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)">
        <van-loading type="spinner" />
      </div>
      <router-view />
    </div>
  </div>
</template>
<script>
// import { Loading } from 'vant'
import {mapGetters}  from "vuex"
export default {
  // provide(){
  //   return {
  //     reload:this.reload
  //   }
  // },
  data(){
    return {
      flag: 0,  //0:正常展示页面，1:页面灰化，2:应急页面
    }
  },
  components:{
    // [Loading.name]: Loading
  },
  computed: {
    ...mapGetters(["loading"])
  },
  created() {
    // 网页是否置灰
    let htmlStyle = document.getElementsByTagName('html')[0]
    if(this.flag == 1) {
      htmlStyle.style = "-webkit-filter: grayscale(100%); -moz-filter: grayscale(100%); -ms-filter: grayscale(100%); -o-filter: grayscale(100%); filter: grayscale(100%)"
    } else {
      htmlStyle.style = "filter: grayscale(0)"
    }
  },
  // methods: {
  //   reload(){
  //     this.isRouterAlive=false
  //     this.$nextTick(()=>{
  //       this.isRouterAlive=true
  //     });
  //   }
  // }
}
</script>
<style lang="scss">
@import 'styles/index.scss';
@import 'styles/icon.css';
// 首页置灰
// html {
//  -webkit-filter: grayscale(100%);
//  -moz-filter: grayscale(100%);
//  -ms-filter: grayscale(100%);
//  -o-filter: grayscale(100%);
//  filter: grayscale(100%);
// }
</style>

