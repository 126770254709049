import Vue from 'vue'
import Router from 'vue-router'
import store from './store/index'
import VueRouter from 'vue-router';
import { postAction } from "@/http/index";

Vue.use(VueRouter);
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }
Vue.use(Router)
// 生产/测试环境，使用路由懒加载
/* eslint-disable no-undef */
const _import =
  process.env.NODE_ENV === 'development' ?
  file => require(`@/view/${file}.vue`).default :
  file => () => import(`@/view/${file}.vue`).then(m => m.default)
const routes = [{
    path: '/',
    redirect: '/home',
  },
  {
    path: '/main',
    name: 'Main',
    component: resolve => require(['@/layout/Layout'], resolve),
    children: [{
        path: '/login',
        component: () => import('@/view/login/index.vue'),
        meta: {
          title: '登录',
          hasObj: {
            hasBar: true,
            hasCenterSearch: false,
            hasNav: false,
          }
        }
      },
      {
        path: '/tb',
        component: () => import('@/view/tbWx/tb.vue'),
        meta: {
          title: '官方淘宝',
          hasObj: {
            hasBar: true,
            hasCenterSearch: false,
            hasNav: true,
          }
        },

      },
      {
        path: '/wx',
        component: () => import('@/view/tbWx/wx.vue'),
        meta: {
          title: '官方微信',
          hasObj: {
            hasBar: true,
            hasCenterSearch: false,
            hasNav: true,
          }
        },

      },
      {
        path: '/forget',
        component: () => import('@/view/login/forget.vue'),
        meta: {
          title: '忘记密码',
          hasObj: {
            hasBar: false,
            hasCenterSearch: false,
            hasNav: false,
          }
        },

      },
      // 首页
      {
        path: '/home',
        name: 'Home',
        component: _import('home/Index'),
        meta: {
          title: '首页',
          // requiresAuth: true // 页面是否需要登录认证
        }
      },
      // 忘记密码
      {
        path: '/forgetPwd',
        name: 'forgetPwd',
        component: _import('forgetPwd/Index'),
        meta: {
          title: '忘记密码',
          // requiresAuth: true // 页面是否需要登录认证
        }
      },
      // 高清/4k业务
      {
        path: '/hd4k',
        name: 'hd4k',
        redirect: '/hd4k/introduction',
        component: _import('hd4k/Index'),
        meta: {
          // requiresAuth: true // 页面是否需要登录认证
        },
        children: [
          // 超清高清介绍
          {
            path: 'introduction',
            name: 'HD4kIntroduction',
            component: _import('hd4k/introduction/Index'),
            meta: {
              title: '超清高清介绍',
              // requiresAuth: true // 页面是否需要登录认证
            },
          },
          // 电视畅看
          {
            path: 'watchTV',
            name: 'watchTV',
            component: _import('hd4k/watchTV/Index'),
            meta: {
              title: '电视畅看',
              // requiresAuth: true // 页面是否需要登录认证
            },
          },
          // 独家内容
          {
            path: 'exclusiveContent',
            name: 'exclusiveContent',
            component: _import('hd4k/exclusiveContent/Index'),
            meta: {
              title: '独家内容',
              // requiresAuth: true // 页面是否需要登录认证
            },
          },
          // 视听游乐
          {
            path: 'audioVisualAmusement',
            name: 'audioVisualAmusement',
            component: _import('hd4k/audioVisualAmusement/Index'),
            meta: {
              title: '视听游乐',
              // requiresAuth: true // 页面是否需要登录认证
            },
          },
          // 订购页
          {
            path: 'orderProduct',
            name: 'orderProduct',
            component: _import('hd4k/orderProduct'),
            meta: {
              title: '订购页',
              // requiresAuth: true // 页面是否需要登录认证
            },
          },
          // 详情页
          {
            path: 'productDetail',
            name: 'productDetail',
            component: _import('hd4k/productDetail'),
            meta: {
              title: '详情页',
              // requiresAuth: true // 页面是否需要登录认证
            },
          },
          // 订购成功
          {
            path: 'orderSuccess',
            name: 'orderSuccess',
            component: _import('hd4k/orderSuccess'),
            meta: {
              title: '订购成功',
              // requiresAuth: true // 页面是否需要登录认证
            },
          },
          // 组合包
          {
            path: 'combinedBag',
            name: 'combinedBag',
            component: _import('hd4k/combinedBag/Index'),
            meta: {
              title: '组合包',
              // requiresAuth: true // 页面是否需要登录认证
            },
          }
        ]
      },
      // 云媒体电视
      {
        path: '/TVOS',
        name: 'TVOS',
        redirect: '/TVOS/digitalTV',
        component: _import('TVOS/Index'),
        meta: {
          // requiresAuth: true // 页面是否需要登录认证
        },
        children: [
          // 数字电视业务
          {
            path: 'digitalTV',
            name: 'digitalTV',
            component: _import('TVOS/digitalTV/Index'),
            meta: {
              title: '数字电视业务',
              // requiresAuth: true // 页面是否需要登录认证
            },
          },
          // 云媒体业务
          {
            path: 'cloudMedia',
            name: 'cloudMedia',
            component: _import('TVOS/cloudMedia/Index'),
            meta: {
              title: '云媒体业务',
              // requiresAuth: true // 页面是否需要登录认证
            },
          },
          // 云媒体2业务
          {
            path: 'cloudMedia2',
            name: 'cloudMedia2',
            component: _import('TVOS/cloudMedia2/Index'),
            meta: {
              title: '云媒体2业务',
              // requiresAuth: true // 页面是否需要登录认证
            },
          }
        ]
      },
      // 广电宽带
      {
        path: '/broadband',
        name: 'broadband',
        redirect: '/broadband/introduction',
        component: _import('broadband/Index'),
        meta: {
          title: '',
          // requiresAuth: true // 页面是否需要登录认证
        },
        children: [
          // 宽带介绍
          {
            path: 'introduction',
            name: 'BBIntroduction',
            component: _import('broadband/introduction/Index'),
            meta: {
              title: '宽带介绍',
              // requiresAuth: true // 页面是否需要登录认证
            },
          },
          // 宽带订购
          {
            path: 'order',
            name: 'order',
            component: _import('broadband/order/Index'),
            meta: {
              title: '宽带订购',
              // requiresAuth: true // 页面是否需要登录认证
            },
          }
        ]
      },
      // 一体机
      {
        path: '/AIO',
        name: 'AIO',
        redirect: '/AIO/introduction',
        component: _import('AIO/Index'),
        meta: {
          // requiresAuth: true // 页面是否需要登录认证
        },
        children: [
          // 一体机介绍
          {
            path: 'introduction',
            name: 'AIOIntroduction',
            component: _import('AIO/introduction/Index'),
            meta: {
              // requiresAuth: true // 页面是否需要登录认证
              title: '一体机介绍',
            },
          }
        ]
      },
      // 服务与支持
      {
        path: '/serviceSupport',
        name: 'serviceSupport',
        redirect: '/serviceSupport/broadbandService',
        component: _import('serviceSupport/Index'),
        meta: {
          // requiresAuth: true // 页面是否需要登录认证
        },
        children: [
          // 宽带服务
          {
            path: 'broadbandService',
            name: 'broadbandService',
            component: _import('serviceSupport/broadbandService/Index'),
            meta: {
              title: '宽带服务',
              // requiresAuth: true // 页面是否需要登录认证
            },
          },
          // 业务办理须知
          {
            path: 'BHI',
            name: 'BHI',
            component: _import('serviceSupport/BHI/Index'),
            meta: {
              title: '业务办理须知',
              // requiresAuth: true // 页面是否需要登录认证
            },
          },
          // 固网资费标准
          {
            path: 'standardRates',
            name: 'standardRates',
            component: _import('serviceSupport/standardRates/Index'),
            meta: {
              title: '固网资费标准',
              // requiresAuth: true // 页面是否需要登录认证
            },
          },
          // 移网资费标准
          {
            path: 'moveStandardRates',
            name: 'moveStandardRates',
            component: _import('serviceSupport/standardRates/moveStandardRates'),
            meta: {
              title: '移网资费标准',
              // requiresAuth: true // 页面是否需要登录认证
            },
          },
          // 投诉与建议
          {
            path: 'CAS',
            name: 'CAS',
            component: _import('serviceSupport/CAS/Index'),
            meta: {
              title: '投诉与建议',
              // requiresAuth: true // 页面是否需要登录认证
            },
          },
          // 常见问题及解答
          {
            path: 'FAQ',
            name: 'FAQ',
            component: _import('serviceSupport/FAQ/Index'),
            meta: {
              title: '常见问题及解答',
              // requiresAuth: true // 页面是否需要登录认证
            },
          },
          // 服务渠道
          {
            path: 'serviceChannels',
            name: 'serviceChannels',
            component: _import('serviceSupport/serviceChannels/Index'),
            meta: {
              title: '服务渠道',
              // requiresAuth: true // 页面是否需要登录认证
            },
          },
          // 营业网点
          {
            path: 'salesNetwork',
            name: 'salesNetwork',
            component: _import('serviceSupport/salesNetwork/Index'),
            meta: {
              title: '营业网点',
              // requiresAuth: true // 页面是否需要登录认证
            },
          },
          // 操作指南
          {
            path: 'operationsGuide',
            name: 'operationsGuide',
            component: _import('serviceSupport/operationsGuide/Index'),
            meta: {
              title: '操作指南',
              // requiresAuth: true // 页面是否需要登录认证
            },
          },
          // 代收费银行
          {
            path: 'collectingBank',
            name: 'collectingBank',
            component: _import('serviceSupport/collectingBank/Index'),
            meta: {
              title: '代收费银行',
              // requiresAuth: true // 页面是否需要登录认证
            },
          },
          // 故障报修
          {
            path: 'malfunctionRepair',
            name: 'malfunctionRepair',
            component: _import('serviceSupport/malfunctionRepair/Index'),
            meta: {
              title: '故障报修',
              // requiresAuth: true // 页面是否需要登录认证
            },
          },
          // 机顶盒售后服务
          {
            path: 'setTopBoxASS',
            name: 'setTopBoxASS',
            component: _import('serviceSupport/setTopBoxASS/Index'),
            meta: {
              title: '机顶盒售后服务',
              // requiresAuth: true // 页面是否需要登录认证
            },
          }
        ]
      }
    ]
  }
]
// add route path
// routes.forEach(route => {
//   route.path = route.path || '/' + (route.name || '')
// })
// const whiteList = ['/login']
const router = new Router({
  routes,
  aftereach (to, from, next) {
    window.scrollTo(0, 0)
  }
})
router.beforeEach((to, from, next) => {
  let code = JSON.parse(sessionStorage.getItem("sessionParams")) ? JSON.parse(sessionStorage.getItem("sessionParams")).custCode : ""
  // 埋点
  // if(to.path == '/home') {
  console.log('路由', to)
  let pointParams = {
    pageName: to.path,
    remark: to.meta.title,
    openId: "",
    topbox: "",
    code: code,
    channel: "ppc"
  }
  console.log('pointParams', pointParams);
  // postAction("/api/addAccess", pointParams).then((res) => {
  postAction("/dzyyt/ppc/business/dzyyt/cust/addAccess", pointParams).then((res) => {
    console.log('埋点', res);
  })
  // }
  next()


//   // 如果去往登录页则放行 
//   if (to.path === '/login' || to.path === '/') {
//     next()
//   } else {
//     //获取token
//     const tokenStr = localStorage.getItem('token')
//     if (!tokenStr) {
//       next('/login')
//     } else {
//       next()
//     }
//   }
})
export {
  router
}