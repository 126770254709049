// vuex/store.js
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import localStorage from '../utils/localStorage'
// 导入各个模块的初始状态和 mutations
import menus from './modules/menus'
import userInfo from './modules/userInfo'
import loading from './modules/loading'
import demandInfo from './modules/demandInfo'
import region from './modules/region'
import message from './modules/message'
import cityInfo from './modules/cityInfo'

Vue.use(Vuex)
export default new Vuex.Store({
  // 组合各个模块
  modules: {
    loading,
    demandInfo,
    userInfo,
    menus,
    region,
    message,
    cityInfo,
  },
  // 插件
  plugins: [createPersistedState({
    key: 'vant-h5' + 'vuex',
    paths: ['menus', 'userInfo', 'region', 'message', 'cityInfo'], // 只缓存service里的state
    getState: (key) => localStorage.get(key),
    setState: (key, state) => localStorage.set(key, state)
  })]
})
