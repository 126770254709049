<template>
  <div :class="[className]">
    <template v-for="(item, i) in routes">
      <el-menu-item v-if="!item.children" :key="item.url" :index="item.url">
        <div>
          <i class="ali-icon" v-html="item.icon" />
          <span slot="title">{{ item.label }}</span>
        </div>
      </el-menu-item>
      <el-submenu v-else :key="`${item.url}-sub`" :index="item.url" :popper-class="`menu-${i+1}`">
        <template slot="title">
          <i class="ali-icon" v-html="item.icon" />
          <span>{{ item.label }}</span>
        </template>
        <TopNavItem :routes="item.children" class="nest-menu" />
      </el-submenu>
    </template>
  </div>
</template>

<script>
export default {
  name: "TopNavItem",
  props: {
    routes: {
      type: Array,
      default: () => {},
    },
    isNest: {
      type: Boolean,
      default: false,
    },
    isShow: {
      type: String,
      default: null,
    },
    className: {
      type: String,
      default: 'menu-wrapper',
    }
  },
  created() {
    this.$options.name = this.$options.name === 'TopNavItem' ? 'TopNavItem' : 'SideNavItem'
  },
  methods: {
    toPath(link) {
      this.$router.push({ path: link });
    },
    isTooltip(label) {
      const width = document.body.clientWidth || window.screen.width;
      if (width < 1600) {
        return label.length > 9;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="scss">
.menu-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > li {
    flex: 1;
    width: 210px;
    text-align: center;
  }
  & > li:first-child {
    padding: 0;
    & > div {
      padding: 0 20px;
      text-align: center;
    }
  }
  .ali-icon {
    padding-right: 10px;
    font-size: 16px;
    margin-left: 0px;
  }
  .el-tooltip {
    padding: 0 10px !important;
  }
  .el-submenu.is-opened .el-submenu__title {
    background-color: #f7faff !important;
  }
  .el-submenu.is-active .el-submenu__title {
    color: rgb(5, 100, 235) !important;
    font-weight: bold;
    .ali-icon {
      color: rgb(5, 100, 235);
    }
  }

  .el-submenu.is-opened > ul > li {
    width: 98%;
    background: #2a3f54 !important;
  }
  .el-submenu.is-opened > ul > li:before {
    background: #425668;
    top: 50%;
    margin-top: -4px;
    content: "";
    height: 8px;
    left: 23px;
    position: absolute;
    right: auto;
    width: 8px;
    z-index: 1;
    border-radius: 50%;
  }
  .el-submenu.is-opened > ul > li:after {
    border-bottom: 1px solid #425668;
    bottom: 0;
    content: "";
    left: 27px;
    position: absolute;
    top: 0;
  }
  .el-submenu__title i {
    color: #595959;
  }
}
#app .el-menu-item.is-active,
#app .ali-icon.is-active {
  font-weight: bold;
}
.el-submenu .el-menu-item {
  padding-right: 1rem;
  .left-line {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .el-tooltip {
      padding: 0 !important;
    }
  }
}
</style>
