import axios from "axios";
import { encrypt, decrypt} from "../utils/cryptoJS"
// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  baseURL: '/', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 1500000, // request timeout
});
// post请求头
// service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
service.defaults.headers.post['Content-Type'] = 'application/json'
// 请求拦截
service.interceptors.request.use(
  (config) => {
    // if(config.isInfo) {
      // config.baseURL = process.env.VUE_APP_BASE_API;
    // }
    config.headers.token = localStorage.getItem('token') ? localStorage.getItem('token') : "";
    config.data = config.data ? encrypt(config.data) : null
    // config.data = config.data ? encrypt(JSON.stringify(config.data)):null
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 返回拦截
service.interceptors.response.use((response) => {
  const res = response.data;
  return res;
}, error => {
  return Promise.reject(error.response);
});

//post请求
export function postAction(url, data) {
  if (data == null) {
    data = {};
  }
  return service({
    url: url,
    method: 'post',
    data: data,
    transformResponse: [
      (data) => {
        let resData = JSON.parse(decrypt(data));
        // console.log('resData', resData)
        return resData;
      }
    ]
  })
}
//get 请求
export function getAction(url, parameter) {
  return service({
    url: url,
    method: 'get',
    params: parameter
  })
}