// initial state
const state = {
	newObjParams: {}, //新建区域的提交的参数
	uploadToken: '',
	regionCityList: [],
	timeStamp: '',
	staySubmit: {},
	editeContent: null,
	lngAndlat: [],
    ciPoints:[],
    uploadFileName:'',
    deleteFileFlage:false,
    historyCiList:[],
    historyCiFlage:false
}

// getters
const getters = {
	newObjParams: (state) => state.newObjParams,
	uploadToken: (state) => state.uploadToken,
	regionCityList: (state) => state.regionCityList,
	timeStamp: (state) => state.timeStamp,
	staySubmit: (state) => state.staySubmit,
	editeContent: (state) => state.editeContent,
	lngAndlat: (state) => state.lngAndlat,
	ciPoints: (state) => state.ciPoints,
	uploadFileNames: (state) => state.uploadFileName,
	deleteFileFlage: (state) => state.deleteFileFlage,
	historyCiListData: (state) => state.historyCiList,
	historyCiFlage: (state) => state.historyCiFlage,
}

// mutations
const mutations = {
	// 参数
	SET_NEW_OBJPARAMS(state, val) {
		state.newObjParams = val
	},
	//设置上传的token
	SET_UPLOADTOKEN(state, val) {
		state.uploadToken = val
	},
	SET_CITYLIST(state, regionCityList) {
		state.regionCityList = regionCityList
	},
	SET_TIMESTAMP(state, val) {
		state.timeStamp = val
	},
	// 设置太提交的参数
	SET_STAYSUBMIT(state, staySubmit) {
		state.staySubmit = staySubmit
	},
	SET_EDITECONTENT(state, editeContent) {
		state.editeContent = editeContent
	},
	SET_LNGANDLAT(state, lngAndlat) {
		state.lngAndlat = lngAndlat
	},
	SET_CIPOINTS(state, ciPoints) {
		state.ciPoints = ciPoints
	},
	SET_UPLOADFILENAME(state, uploadFileName) {
		state.uploadFileName = uploadFileName
	},
	SET_DELETEFILEFLAGE(state, deleteFileFlage) {
		state.deleteFileFlage = deleteFileFlage
	},
	SET_HISTORYCILIST(state, historyCiList) {
		state.historyCiList = historyCiList
	},
	SET_HISTORYCIFLAGE(state, historyCiFlage) {
		state.historyCiFlage = historyCiFlage
	},
}

const actions = {}

export default {
	state,
	getters,
	actions,
	mutations,
}
