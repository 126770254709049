const smsCount = function(val) {
	// 短信字数
    const num = val.split('\n').length - 1
	return val.length + num
}
const chargingCount = function(val) {
    const num = val.split('\n').length - 1
	let total = num + val.length
	// 计费条数
	if (total <= 70) {
		return total ? 1 : 0
	} else {
		return Math.ceil(total / 67)
	}
}
const smsTemCount = function(val) {
	// 短信模板字数
	return val.length ? val.replace(/{{/g, '').replace(/}}/g, '').length : 0
}
const chargingTemCount = function(val) {
	// 短信模板计费条数
	const sms = val.length && val.replace(/{{/g, '').replace(/}}/g, '')
	if (sms.length <= 70) {
		return sms.length ? 1 : 0
	} else {
		return Math.ceil(sms.length / 67)
	}
}
export { smsCount, chargingCount, smsTemCount, chargingTemCount }
