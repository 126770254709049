import CryptoJS from 'crypto-js'
const keyStr = process.env.VUE_APP_SECRET_KEY
const ivStr = process.env.VUE_APP_SECRET_IV
//加密
export function encrypt( data ){
  if( typeof data === "object" ){
      // 如果传入的data是json对象，先转义为json字符串
      try {
          data = JSON.stringify(data)
      } catch (error) {
          console.log("error:",error)
      }
  }
    // 统一将传入的字符串转成UTF8编码
  let dataHex = CryptoJS.enc.Utf8.parse( data ) // 需要加密的数据
  let keyHex = CryptoJS.enc.Utf8.parse( keyStr ) // 秘钥
  let ivHex = CryptoJS.enc.Utf8.parse( ivStr ) // 偏移量
  let encrypted = CryptoJS.AES.encrypt( dataHex , keyHex , {
    iv: ivHex,
    mode: CryptoJS.mode.CBC, // 加密模式
    padding: CryptoJS.pad.Pkcs7
  })
  let encryptedVal = encrypted.toString()
  return encryptedVal //  返回加密后的值
}
//解密
export function decrypt( encryptedVal ) {
/*
  传入的key和iv需要和加密时候传入的key一致  
*/
  // 统一将传入的字符串转成UTF8编码
    let encryptedValSet = (encryptedVal + '').replace(/\n*$/g, '').replace(/\n/g, '')  //去除换行符
    let encryptedHexStr = CryptoJS.enc.Base64.parse( encryptedValSet  );
    let srcs = CryptoJS.enc.Base64.stringify( encryptedHexStr );
    let keyHex = CryptoJS.enc.Utf8.parse( keyStr ) // 秘钥
    let ivHex = CryptoJS.enc.Utf8.parse( ivStr ) // 偏移量
    let decrypt = CryptoJS.AES.decrypt( srcs , keyHex , {
        iv: ivHex, 
        mode: CryptoJS.mode.CBC, 
        padding: CryptoJS.pad.Pkcs7
    });
    let result = CryptoJS.enc.Utf8.stringify(decrypt).toString();
    return result
}

  
  