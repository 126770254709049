// initial state
const state = {
  sidebar: [],
  open: 1, // 1：展开 0：折叠
  show: true,
  menuList: []
}
// getters
const getters = {}
// mutations
const mutations = {
  // 展开/折叠菜单栏
  SET_SIDEBAR_TOGGLE (state, val) {
    state.open = val
  },
  // 赋用户权限
  INIT_SIDEBAR_DATA (state, val) {
    state.sidebar = val
  },
  SET_SHOW_BOOLEAN (state, val) {
    state.show = val
  },
  INIT_MENU_TREE (state, value) {
    state.menuList = value
  }
}
const actions = {
  // 展开/折叠菜单栏
  set_sidebar_toggle ({ commit }, val) {
    commit('SET_SIDEBAR_TOGGLE', val)
  },
  set_show_boolean ({ commit }, val) {
    commit('SET_SHOW_BOOLEAN', val)
  },
  // 登录成功之后，取用户的权限信息
  init_sidebar_data ({ commit }, val) {
    return new Promise((resolve) => {
      commit('INIT_SIDEBAR_DATA', val)
      resolve()
    })
  },
  initmenuTree ({ commit }, val) {
    commit('INIT_MENU_TREE', val)
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
